import { render, staticRenderFns } from "./BrandMonitoring.vue?vue&type=template&id=71e42294&scoped=true&"
import script from "./BrandMonitoring.vue?vue&type=script&lang=js&"
export * from "./BrandMonitoring.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e42294",
  null
  
)

export default component.exports